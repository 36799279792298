export const languageObj = [
  {
    sourceLanguage: "zh",
    targetLanguage: [
      "en",
      "ru",
      "es",
      "vi",
      "ms",
      "zh-TW",
      "th",
      "id",
      "pt",
      "pl",
      "ja",
      "ko",
    ],
  },
  {
    sourceLanguage: "en",
    targetLanguage: [
      "zh",
      "ru",
      "es",
      "fr",
      "pt",
      "vi",
      "ms",
      "th",
      "pl",
      "ja",
      "id",
      "ko",
    ],
  },
];

export const languageCode = {
  zh: "中文",
  "zh-TW": "繁体中文",
  en: "英语",
  ru: "俄语",
  es: "西班牙语",
  pt: "葡萄牙语",
  fr: "法语",
  ja: "日语",
  ko: "韩语",
  id: "印尼语",
  th: "泰语",
  ms: "马来语",
  vi: "越南语",
  pl: "波兰语",
};
