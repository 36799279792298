import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import axios from "axios";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import managerHeader from "./components/managerHeader.vue";
import { useUserStore } from "@/store/user";
import { origin } from "@/common/config";
export default {
  __name: 'managerIndex',

  setup(__props) {
    const userStore = useUserStore();
    const router = useRouter();
    onMounted(() => {
      if (!userStore.user.jwt) {
        router.replace({
          redirect: location.pathname
        });
      }
    });
    axios.get(`${origin}/users/me`, {
      headers: {
        Authorization: "Bearer " + userStore.user.jwt
      }
    }).then(res => {
      userStore.setOtherUserData(res);
    }).catch(() => {
      router.replace({
        path: "/login",
        query: {
          redirect: location.pathname
        }
      });
    });
    axios.get(`${origin}/user/getVipInfo`, {
      headers: {
        Authorization: "Bearer " + userStore.user.jwt
      }
    }).then(res => {
      if (res.data) {
        userStore.setVipLevel({ ...res.data.vip,
          expires_at: res.data.expires_at
        });
      }
    });
    axios.get(`${origin}/user/getRemain`, {
      headers: {
        Authorization: "Bearer " + userStore.user.jwt
      }
    }).then(res => {
      if (res.data && res.data.data) {
        userStore.setTranslateImage(res.data.data["translate-image"]);
        userStore.setCutoutImage(res.data.data["cutout-image"]);
      }
    });
    axios.get(`${origin}/user/getUserInfo`, {
      headers: {
        Authorization: "Bearer " + userStore.user.jwt
      }
    }).then(res => {
      console.log(res);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(managerHeader), _createVNode(_component_router_view, {
        name: "model"
      })], 64);
    };
  }

};