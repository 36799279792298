import managerIndex from "@/views/ec/managerIndex.vue";
import transImage from "./transImage";
import cutoutImage from "./cutoutImage";
import vip from "./vip";
export default [
  {
    path: "/ec",
    name: "manager",
    component: managerIndex,
    redirect: { name: "trasIndex" },
    children: [...transImage, ...cutoutImage, ...vip],
  },
];
