import translateIndex from "@/views/ec/translateImage/translateIndex.vue";
import toolsView from "@/views/ec/translateImage/views/toolsView";
import vipView from "@/views/ec/translateImage/views/vipView";
import indexView from "@/views/ec/translateImage/views/indexView";
export default [
  {
    path: "transimage",
    name: "transimage",
    components: {
      model: translateIndex,
    },
    redirect: { name: "trasIndex" },
    children: [
      {
        path: "",
        name: "trasIndex",
        components: {
          translate: indexView,
        },
      },
      {
        path: "tools",
        name: "tools",
        components: {
          translate: toolsView,
        },
      },
      {
        path: "vip",
        name: "vip",
        components: {
          translate: vipView,
        },
      },
    ],
  },
];
