import subscribeIndex from "@/views/ec/vip/subscribeIndex.vue";
import vipLevel from "@/views/ec/vip/views/vipLevel.vue";
import packageView from "@/views/ec/vip/views/packageView.vue";
export default [
  {
    path: "subscribe",
    name: "subscribe",
    components: {
      model: subscribeIndex,
    },
    redirect: { name: "vip" },
    children: [
      {
        path: "vip",
        name: "vip",
        components: {
          vipModel: vipLevel,
        },
      },
      {
        path: "package",
        name: "package",
        components: {
          vipModel: packageView,
        },
      },
    ],
  },
];
