import { defineStore } from "pinia";
export const useVipPackageStore = defineStore("vipPackage", {
  state: () => ({
    vipLevel: [],
    package: {},
    vipPackage: [],
  }),
  getters: {},
  actions: {
    initVipLevel(data) {
      this.vipLevel = data;
    },
    initPackage(data) {
      this.package = data;
    },
    initVipPackage(data) {
      this.vipPackage = data;
    },
  },
});
