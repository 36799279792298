import cutoutIndex from "@/views/ec/cutoutImage/cutoutIndex.vue";
import indexView from "@/views/ec/cutoutImage/views/indexView.vue";
import toolsView from "@/views/ec/cutoutImage/views/toolsView.vue";
export default [
  {
    path: "cutoutimage",
    name: "cutoutimage",
    components: {
      model: cutoutIndex,
    },
    redirect: { name: "cutoutIndex" },
    children: [
      {
        path: "",
        name: "cutoutIndex",
        components: {
          cutout: indexView,
        },
      },
      {
        path: "tools",
        name: "cutoutTools",
        components: {
          cutout: toolsView,
        },
      },
    ],
  },
];
