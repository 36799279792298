import { createRouter, createWebHistory } from "vue-router";
import indexView from "../views/index/indexView.vue";
import loginView from "../views/index/loginView.vue";
import forgetView from "../views/index/forgetView.vue";
import ecRouter from "./ecRouter";
import editorRouter from "./editorRouter";
import cutoutEditRouter from "./cutoutEditRouter";
const routes = [
  {
    path: "/",
    name: "index",
    component: indexView,
  },
  {
    path: "/login",
    name: "login",
    component: loginView,
  },
  {
    path: "/forgetpass",
    name: "forgetpass",
    component: forgetView,
  },
  ...ecRouter,
  ...editorRouter,
  ...cutoutEditRouter,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
