export function base64UrlToBlob(urlData) {
  var arr = urlData.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

/**
 * 根据图片的url转换对应的base64值
 * @param { String } imageUrl 如：http://xxxx/xxx.png
 * @returns base64取值
 * @Note 注意：可能涉及图片访问有跨域问题，让后台解决即可。
 */
export function urlToBase64(imageUrl) {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    let img = new Image();
    img.crossOrigin = "Anonymous"; // 解决Canvas.toDataURL 图片跨域问题
    img.src = imageUrl;
    img.onload = function () {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0, img.width, img.height); // 参数可自定义
      const dataURL = canvas.toDataURL("image/jpeg", 1); // 获取Base64编码
      resolve(dataURL);
      canvas = null; // 清除canvas元素
      img = null; // 清除img元素
    };
    img.onerror = function () {
      reject(new Error("Could not load image at " + imageUrl));
    };
  });
}
