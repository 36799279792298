import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7b5462a4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "cover_img"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "item-info"
};
const _hoisted_4 = {
  key: 0,
  class: "progress"
};
import { computed, ref, onMounted } from "vue";
import dayjs from "dayjs";
import axios from "axios";
import { origin } from "@/common/config";
import { languageCode } from "@/common/lang";
import { useUserStore } from "@/store/user";
export default {
  __name: 'translateItem',
  props: ["task", "isCutout"],

  setup(__props) {
    const props = __props;
    const percentage = ref(0);
    const userStore = useUserStore();
    const date = computed(() => {
      return dayjs(props.task.createdAt).format("MM-DD HH:mm:ss");
    });

    const toEditor = function () {
      if (props.isCutout) {
        window.open(`${location.origin}/cutoutEdit?taskId=${props.task.id}`);
      } else {
        window.open(`${location.origin}/editor?taskId=${props.task.id}`);
      }
    };

    const opt = {
      headers: {
        Authorization: "Bearer " + userStore.user.jwt
      }
    };
    onMounted(() => {
      if (props.task.images === 0) {
        let interval = null;
        !interval && (interval = setInterval(() => {
          axios.get(`${origin}/user/getRemain`, opt).then(res => {
            if (res.data && res.data.data) {
              userStore.setTranslateImage(res.data.data["translate-image"]);
              userStore.setCutoutImage(res.data.data["cutout-image"]);
            }
          });
          axios.post(`${origin}/task/getDetail`, {
            taskId: props.task.id
          }, opt).then(({
            data
          }) => {
            if (data && data.data) {
              const imgList = data.data.consume_details.filter(a => a.outputs.length); // eslint-disable-next-line vue/no-mutating-props
              // props.task.images = data.data.consume_details.length;
              // eslint-disable-next-line vue/no-mutating-props

              props.task.images = imgList.length;
              percentage.value = Math.ceil(props.task.images / props.task.imageCount * 100);

              if (props.task.images === props.task.imageCount) {
                clearInterval(interval);
                interval = null;
              }
            }
          });
        }, 1000));
      }
    });
    return (_ctx, _cache) => {
      const _component_el_progress = _resolveComponent("el-progress");

      const _directive_loading = _resolveDirective("loading");

      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "translate-item",
        onClick: toEditor
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        src: props.task.cover,
        alt: ""
      }, null, 8, _hoisted_2)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, [_createElementVNode("span", null, _toDisplayString(props.task.imageCount) + "P", 1), _createTextVNode(" " + _toDisplayString(_unref(languageCode)[props.task.targetLanguage] ? "/" : "") + " ", 1), _createElementVNode("span", null, _toDisplayString(_unref(languageCode)[props.task.targetLanguage]), 1)]), _createElementVNode("p", null, _toDisplayString(_unref(date)), 1)]), props.task.images !== undefined && props.task.images !== props.task.imageCount ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_progress, {
        type: "circle",
        percentage: percentage.value
      }, null, 8, ["percentage"])])) : _createCommentVNode("", true)])), [[_directive_loading]]);
    };
  }

};