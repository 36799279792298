var defaultOpts = {
    ignore: [],
    scale: 1
};

export function getContextImgCanvas(width, height) {
    var canvas = document.createElement('canvas');
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    return canvas;
}

export function analyze(opts, data) {
    if (opts === void 0) opts = defaultOpts;
    try {
        opts = Object.assign({}, defaultOpts,
            opts);
        var ignore = opts.ignore;
        var scale = opts.scale;

        if (scale > 1 || scale <= 0) {
            console.warn(("You set scale to " + scale + ", which isn't between 0-1. This is either pointless (> 1) or a no-op (≤ 0)"));
        }
        return getCounts(data, ignore);
    } catch (e) {
        return e;
    }
}

function getCounts(data, ignore) {
    var countMap = {};

    for (var i = 0; i < data.length; i += 4) {
        var alpha = data[i + 3];
        if (alpha === 0) {
            continue;
        }
        var rgbComponents = Array.from(data.subarray(i, i + 3));
        if (rgbComponents.indexOf(undefined) !== -1) {
            continue;
        }
        var color = alpha && alpha !== 255 ? ("rgba(" + (rgbComponents.concat([alpha]).join(',')) + ")") : ("rgb(" + (rgbComponents.join(',')) + ")");
        if (ignore.indexOf(color) !== -1) {
            continue;
        }

        if (countMap[color]) {
            countMap[color].count++;
        } else {
            countMap[color] = {
                color: color,
                count: 1
            };
        }
    }

    var counts = Object.values(countMap);
    return counts.sort(function (a, b) {
        return b.count - a.count;
    });
}