import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import animated from "animate.css";
import "@/assets/less/init.less";
import "element-plus/theme-chalk/el-notification.css";
import "@/assets/fonts/iconfont/iconfont.css";
const app = createApp(App).use(router).use(createPinia()).use(animated);
app.mount("#app");
