import { defineStore } from "pinia";
export const useTranslateTaskStore = defineStore("translateTaskStore", {
  state: () => ({
    lateTasks: [],
    newTasks: [],
    manageTask: [],
  }),
  getters: {
    getSelectIds(state) {
      return state.manageTask
        .filter((value) => value.select)
        .map((value) => value.id)
        .join(",");
    },
  },
  actions: {
    initLateTasks(tasks) {
      //   console.log(tasks);
      this.lateTasks = tasks;
    },
    initManageTask(tasks) {
      this.manageTask = tasks;
    },
    addManageTask(tasks) {
      this.manageTask = this.manageTask.concat(tasks);
    },
    addNewTask(task) {
      this.newTasks.unshift(task);
    },
    updateActiveTask(data) {
      console.log(data);
      this.activeTask.id = data.taskId;
      this.activeTask.images = [];
      data.data.forEach((image) => {
        if (image.data) {
          this.activeTask.images.push(image.data.serviceResult.data);
        }
      });
      this.activeTask.sourceLanguage = "zh";
      this.activeTask.targetLanguage = "en";
    },
    removeTask(id) {
      const index = this.manageTask.findIndex((a) => a.id === id);
      this.manageTask.splice(index, 1);
    },
  },
});
