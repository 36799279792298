import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-ba31b5f0"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  id: "vip"
};
const _hoisted_2 = {
  class: "container"
};
import sidebarView from "../components/sidebarView.vue";
import infoView from "../components/infoView.vue";
import { useVipPackageStore } from "@/store/vipPackage";
import { ref } from "vue";
import axios from "axios";
import { origin } from "@/common/config";
import { useUserStore } from "@/store/user";
import Bus from "@/common/Bus";
export default {
  __name: 'subscribeIndex',

  setup(__props) {
    const userStore = useUserStore();
    const vipPackageStore = useVipPackageStore();
    const pageTitle = ref("充值中心");
    const tabBar = ref([{
      title: "按会员等级订购",
      icon: "icon-vip",
      path: "/ec/subscribe/vip"
    }, {
      title: "按图片张数订购",
      icon: "icon-xiangce",
      path: "/ec/subscribe/package"
    }]);
    axios.get(`${origin}/vips?populate[vip_services][populate][0]=service`).then(res => {
      if (res.data && res.data.data) {
        const data = res.data.data.map(item => ({
          id: item.id,
          title: item.attributes.title,
          translateDayLimit: item.attributes.vip_services.data.find(service => service.attributes.service.data.attributes.name === "translate-image").attributes.dayLimit,
          cutoutDayLimit: item.attributes.vip_services.data.find(service => service.attributes.service.data.attributes.name === "cutout-image").attributes.dayLimit
        }));
        vipPackageStore.initVipLevel(data);
      }
    });
    axios.get(`${origin}/servicepackages`).then(res => {
      if (res.data && res.data.data) {
        const data = res.data.data.map(sPackage => ({
          id: sPackage.id,
          name: sPackage.attributes.name,
          description: sPackage.attributes.description,
          amount: sPackage.attributes.amount,
          price: sPackage.attributes.price / 100,
          title: sPackage.attributes.title,
          eachPrice: (sPackage.attributes.price / 100 / sPackage.attributes.amount).toFixed(3)
        }));
        vipPackageStore.initPackage(data);
      }
    });
    axios.get(`${origin}/vip-packages?populate[vip][populate][vip_services][populate][0]=service`).then(res => {
      if (res.data && res.data.data) {
        const data = res.data.data.map(pack => ({
          days: pack.attributes.days,
          price: pack.attributes.price / 100,
          title: pack.attributes.title,
          vid: pack.attributes.vip.data.id,
          id: pack.id,
          eachPrice: eachPriceHandler(pack.attributes.vip.data.id, dayLimit(pack.attributes.vip.data.attributes), pack.attributes.price, pack.attributes.days)
        }));
        vipPackageStore.initVipPackage(data);
      }
    });
    Bus.$on("reloadRemain", function () {
      axios.get(`${origin}/user/getRemain`, {
        headers: {
          Authorization: "Bearer " + userStore.user.jwt
        }
      }).then(res => {
        if (res.data && res.data.data) {
          userStore.setTranslateImage(res.data.data["translate-image"]);
          userStore.setCutoutImage(res.data.data["cutout-image"]);
        }
      });
      axios.get(`${origin}/user/getVipInfo`, {
        headers: {
          Authorization: "Bearer " + userStore.user.jwt
        }
      }).then(res => {
        if (res.data) {
          userStore.setVipLevel({ ...res.data.vip,
            expires_at: res.data.expires_at
          });
        }
      });
    });

    const eachPriceHandler = function (vid, count, price, days) {
      if (vid >= 4) {
        return (price / 100 / (count * days)).toFixed(3) + "元/张";
      } else {
        return (price / 100 / days).toFixed(3) + "元/天";
      }
    };

    const dayLimit = function (vipInfo) {
      return vipInfo.vip_services.data.reduce((preValue, currentValue) => preValue + currentValue.attributes.dayLimit, 0);
    };

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(sidebarView, {
        tabBar: tabBar.value,
        pageTitle: pageTitle.value
      }, null, 8, ["tabBar", "pageTitle"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, {
        name: "vipModel"
      })]), _createVNode(infoView)]);
    };
  }

};