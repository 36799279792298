import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-61df68de"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  id: "cutout"
};
const _hoisted_2 = {
  class: "container"
};
import sidebarView from "../components/sidebarView.vue";
import infoView from "../components/infoView.vue";
import { ref } from "vue";
export default {
  __name: 'cutoutIndex',

  setup(__props) {
    const pageTitle = ref("优商抠图工具");
    const tabBar = ref([{
      title: "首页",
      icon: "icon-home",
      path: "/ec/cutoutimage"
    }, {
      title: "任务管理中心",
      icon: "icon-task",
      path: "/ec/cutoutimage/tools"
    }]);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(sidebarView, {
        pageTitle: pageTitle.value,
        tabBar: tabBar.value
      }, null, 8, ["pageTitle", "tabBar"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, {
        name: "cutout"
      })]), _createVNode(infoView)]);
    };
  }

};