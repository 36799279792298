import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-01e6a2a2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "siderbar"
};
const _hoisted_2 = {
  class: "siderbar-title"
};
const _hoisted_3 = {
  class: "nav"
};
import { useRoute } from "vue-router";
export default {
  __name: 'sidebarView',
  props: ["tabBar", "pageTitle"],

  setup(__props) {
    const props = __props;
    const route = useRoute();
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createElementBlock("aside", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(props.pageTitle), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabBar, bar => {
        return _openBlock(), _createBlock(_component_router_link, {
          class: _normalizeClass(["nav-item", _unref(route).path === bar.path ? 'is_active' : '']),
          to: bar.path,
          key: bar
        }, {
          default: _withCtx(() => [_createElementVNode("i", {
            class: _normalizeClass(["iconfont", bar.icon])
          }, null, 2), _createElementVNode("span", null, _toDisplayString(bar.title), 1)]),
          _: 2
        }, 1032, ["to", "class"]);
      }), 128))])]);
    };
  }

};