import { defineStore } from "pinia";
// const userData = localStorage.getItem("user");
const jwt = localStorage.getItem("jwt");
export const useUserStore = defineStore("userStore", {
  state: () => ({
    user:
      // JSON.parse(userData) ||
      {
        mobile: "",
        username: "",
        // jwt: "",
        jwt: jwt,
        id: 0,
        blocked: false,
        confirmed: true,
        email: "",
        provider: "",
        vip: {},
        translateImage: {},
        cutoutImage: {},
      },
  }),
  getters: {},
  actions: {
    setUserData({ data }) {
      this.user.jwt = data.jwt;
      // localStorage.setItem("user", JSON.stringify(this.user));
      localStorage.setItem("jwt", this.user.jwt);
    },
    setOtherUserData({ data }) {
      this.user.blocked = data.blocked;
      this.user.confirmed = data.confirmed;
      this.user.email = data.email;
      this.user.id = data.id;
      this.user.mobile = data.mobile;
      this.user.username = data.username;
      this.user.provider = data.provider;
      // localStorage.setItem("user", JSON.stringify(this.user));
    },
    setVipLevel(vipData) {
      this.user.vip = vipData;
    },
    setTranslateImage(data) {
      this.user.translateImage = data;
    },
    setCutoutImage(data) {
      this.user.cutoutImage = data;
    },
  },
});
